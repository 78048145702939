@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.page-dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #DADADA;

  .header-container {
    background-color: #6945e0;
    width: 100vw;

    .dashboard-header-container {
      width: 100%;
      padding: 0 20px;

      @include media-breakpoint-up(md) {
        margin-left: calc(50vw - (816px / 2));
        padding-left: 30px;
        padding-right: calc(50vw - (768px / 2) + 48px);
      }

      @include media-breakpoint-up(xl) {
        margin-left: calc(50vw - (1024px / 2));
        padding-left: 0;
        padding-right: calc(50vw - (1024px / 2) + 88px);
      }
    }
  }

  .dashboard-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    margin-top: -66px;
    max-width: 278px;
    
    @include media-breakpoint-up(xs) {
      max-width: 516px;
      justify-content: unset;
    }

    @include media-breakpoint-up(md) {
      max-width: 804px;
      justify-content: unset;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1072px;
    }
    
    .dashboard-card-container {
      margin: 0 9px;
      margin-bottom: 36px;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 18px;
      }
      
      @include media-breakpoint-up(lg) {
        margin: 0 24px;
        margin-bottom: 50px;
      }
    }
  }
}
