@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.column-layout-block {
  @include panel-style();

  .column-layout-block-title {
    margin-bottom: 9px;
  }

  .column-layout-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-bottom: 12px;
    margin: 0 -6px;

    .column-layout-body-child {
      width: 100%;
      flex: 1;
    }

    @include media-breakpoint-up(xs) {
      flex-direction: row;
      flex-wrap: wrap;

      .column-layout-body-child {
        min-width: calc(50% - 24px)!important;
        margin: 0 6px;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      max-width: unset;
      flex-wrap: nowrap;
    
      .column-layout-body-child {
        width: unset;
        min-width: unset!important;
      }
    }
  }
}
