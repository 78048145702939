@import 'src/styles/colors';

.product-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
  height: 108px;
  width: 108px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 1.5rem;
  color: rgba($FLEXBOX_TEXT_BLACK, .5);

  &.bordered {
    border: 3px solid white;
  }
}
