.daily-sales-income {
  background-color: white;
  margin: 30px auto;
  border-radius: 18px;
  height: 500px;
  overflow: hidden;

  .daily-sales-income-title {
    font-size: 18px;
    font-weight: 600;
    padding: 18px 24px;
    border-bottom: 2px solid #f1f1ef;
  }
}
