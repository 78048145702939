@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.page-location {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #DADADA;
  overflow: auto;

  .location-page-body {
    width: 100%;
    max-width: 884px;
    margin: 0 auto;
    
    .flbx-app-button {
      color: #6945E0;
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 20px;
      margin-top: -34px;
    }
    
    .locations-search-container {
      padding: 0 12px;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    
      .flbx-search-container {
        margin-top: 24px;
      }

      .locations-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 36px;
        margin: 0 -12px;

        .location-card-container {
          flex: calc(50% - 24px);
          margin: 0 12px;
          margin-bottom: 30px;
        }

        .location-card-placeholder {
          flex: calc(50% - 24px);
          margin: 0 12px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
