@mixin button-box-shadow() {
  box-shadow: -2px -2px 5px white, 2px 2px 5px #BABECC;
}

@mixin button-box-shadow-active() {
  box-shadow: inset 1px 1px 2px #BABECC, inset -1px -1px 2px white;
}

@mixin neumor-box-1() {
  background-color: #f1f6fa;
  border-right: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  box-shadow: inset -5px -5px 10px 0 #FAFBFF40, inset 5px 5px 10px 0 #C6CADBAB;
  border-radius: 28px;
}

@mixin neumor-box-2() {
  background-color: #f1f6fa;
  border-right: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  box-shadow: inset 5px 5px 10px 0 #C6CADB;
  border-radius: 28px;
}
