@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.pos-category-product-card-container {
  width: 100%;
  margin: 2rem 0 4rem;
  display: flex;
  justify-content: center;
  user-select: none;

  @include media-breakpoint-up(xs) {
    width: 50%;
  }

  @include media-breakpoint-up(md) {
    width: 33%;
  }

  @include media-breakpoint-up(xl) {
    width: 25%;
  }

  .new-item-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 16rem;
    width: 30rem;
    max-width: 45rem;
    border: 3px dashed #dedede;
    padding: 3rem;
    border-radius: 4rem;
    
    .new-item-button-label {
      padding-top: 4rem;
    }
  }

  .pos-category-product-card {
    width: 30rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    transition: all .75s ease;
    border-radius: 3rem;
    cursor: pointer;
    padding: 1.5rem;
    background-color: white;
    overflow: hidden;
    border: .5rem solid rgba(231, 231, 231, .5);

    .pos-category-color {
      width: 100%;
      background-color: #6645e0;
      position: absolute;
      left: 0;
      top: 22rem;
      bottom: -4rem;
      transform: skewY(-7deg);
    }

    .product-card-image {
      display: flex;
      justify-content: center;
      background-color: #dedede;
      align-items: center;
      width: 26rem;
      height: 26rem;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 2rem;
      color: rgba($FLEXBOX_TEXT_BLACK, .5);
      border: 2px solid white;
      z-index: 1;
    }

    .product-card-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 100%;
      border-radius: 1rem;
      z-index: 1;

      .product-card-content-row {
        display: flex;
        padding: 1rem;
        flex: 1;


        &.title {
          padding: 1rem 2rem;
          justify-content: center;
          align-items: center;
          padding-bottom: 1.5rem;
          font-size: 2.5rem;
          font-family: 'Ubuntu';
          color: white;
          font-weight: 500;
        }

        &.price {
          justify-content: flex-end;
          align-items: flex-end;
          padding-right: 1.5rem;
          padding-bottom: 1.5rem;
        }

        .price-tag {
          background-color: white;
          padding: 0 2rem;
          border-radius: 1rem;
          font-size: 2.5rem;
        }
      }
    }
  }
}
