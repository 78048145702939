/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(#F4BABA, .2) inset;
  transition: background-color 5000s ease-in-out 0s;
}

* {
  outline: none;

  &:focus {
    outline: none;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 6px;
}

button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
