@import 'src/styles/breakpoints';

.ant-drawer.app-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    
    @include media-breakpoint-up(md) {
      max-width: 80rem;
      height: calc(100vh - 60px);
      margin: 30px;
      border-radius: 18px;
      overflow: hidden;
    }
  }
}
