@import 'src/styles/colors';

.ant-drawer.drawer-product-profile {
  .ant-drawer-body {
    padding: 0;
  }

  .products-action-header {
    display: flex;
    justify-content: flex-end;
  }

  .product-profile-header {
    display: flex;
    align-items: flex-start;
    padding: 3rem 6rem 2rem;

    .product-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 18rem;

      .product-title {
        font-size: 3rem;
        line-height: 4rem;
        padding: 3rem;
      }

      .product-price {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;

        .price-tag {
          background-color: white;
          padding: 0.5rem 3rem;
          border-radius: 1rem;
          font-size: 3rem;
        }
      }
    }
  }

  .edit-product-action-button {
    display: flex;
    padding: 5rem 20rem;
  }
}
