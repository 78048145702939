@import 'src/styles/breakpoints';

.content-panel-container {
  width: 100%;
  max-width: 672px;
  margin: 0 auto;

  .daily-sales-list {
    background: #E0E0E0;
    border-radius: 18px;
  
    tbody {
      .ant-table-row-expand-icon-cell {
        background-color: white;
      }
    }
  
    .ant-table-row-expand-icon-cell {
      position: sticky;
      left: 0;
      z-index: 20;
      box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, .1);
  
      @include media-breakpoint-up(md) {
        box-shadow: none;
      }
    }
  
    .sales-action-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      padding-bottom: 0;
      
      @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
      }
  
      .list-main-title {
        font-family: Ubuntu;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: #4F4F4F;
      }
  
      .sales-switch-selector-container {
        width: 100%;
        max-width: 300px;
        padding-top: 18px;
  
        @include media-breakpoint-up(md) {
          padding: 0;
        }
      }
    }
  
    .table-footer-sum-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-footer-sum-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .sum-per-piece {
          @include media-breakpoint-up(md) {
            padding-right: 18px;
          }
        }

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  
    .daily-sales-table, .sales-list-table {
      padding: 24px;
  
      .ant-table {
        border-radius: 18px;
        overflow: hidden;
      }
  
      .ant-table-footer {
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
      }
  
      .ant-table-expanded-row {
        .ant-table-container, .ant-table-container table>thead>tr:first-child th:first-child {
          border-top-left-radius: 0!important;
        }
    
        .ant-table-container table>thead>tr:first-child th:last-child {
          border-top-right-radius: 0!important;;
        }

        .ant-table {
          border-radius: 2px;
          overflow: hidden;
        }
      }
  
      .ant-table-container, .ant-table-container table>thead>tr:first-child th:first-child {
        border-top-left-radius: 18px!important;
      }
  
      .ant-table-container table>thead>tr:first-child th:last-child {
        border-top-right-radius: 18px!important;
      }
    }
  
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
