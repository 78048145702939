.product-picture-dnd {
  .uppy-DragDrop-inner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    svg {
      margin-top: 24px;
      width: 24px;
      height: 24px;
    }
  }

  .image-editor {
    padding: 2rem;

    .uppy-Root {
      min-height: 420px;
      background-color: #eeeeee;
      border-radius: 3rem;
    }

    .crop-workspace-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 400px;
      width: 400px;
      box-sizing: content-box;
      margin: 0 auto;
      background-color: #eeeeee;
      border-radius: 3rem;
      padding: 3rem;
    }

    .crop-actions-container {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem;

      .action-button {
        flex: 1;
      }
    }
  }
}
