.assign-warehouse-product-block {
  flex: 1;

  .column-layout-body {
    margin-bottom: 16px;
  }

  .ingredient-container {
    display: flex;
    align-items: center;

    .ingredient-divider {
      background-color: 'gainsboro';
      min-width: 66px;
      width: 66px;
      height: 66px; 
      border-radius: 9px;
      margin-right: 18px;

      .ingredient-image {
        width: 100%;
        height: 100%;
        border-radius: 9px;
      }
    }
  }
}
