@import 'src/styles/breakpoints';

.product-editor {
  .drawer-input-container {
    .flbx-input {
      margin-bottom: 24px;
      margin-top: 0;
    }

    .flbx-app-button {
      margin: 0;
      margin-top: 24px;
    }
  }

  .drawer-subtitle {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin: 12px 0;
  }

  .exception-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px dashed #dedede;
    border-radius: 24px;
    padding: 0 12px;
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 24px;
    }

    +.flbx-app-button {
      margin-top: 0;
    }

    .exception-select-container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      padding: 24px 0;

      .flbx-select {
        margin: 0;

        &:first-of-type {
          margin-bottom: 24px;
        }

        &:last-of-type {
          margin-top: 24px;
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .flbx-tree-select, .flbx-input-number, .flbx-select {
    margin-top: 24px;
  }

  .product-image-container {
    padding: 36px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -12px;

    @include media-breakpoint-up(xs) {
      flex-direction: row;
    }

    .product-image-actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-top: 5rem;
      
      @include media-breakpoint-up(xs) {
        margin-top: 0;
        margin-left: 5rem;
      }

      .flbx-action-button {
        margin: 0;
      }
    }
  }
}

.category-titles-actions {
  display: flex;
  margin-top: 3rem;
}

.ant-input-number {
  &.price-input {
    width: 22rem;
  }
}
