@import 'styles/flbx-ui-style';

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}

.ant-table-wrapper.sales-list-table {
  .ant-table-expanded-row > .ant-table-cell {
    background-color: #E0E0E0;
  }
}

.sales-list {
  .sale-card {
    background-color: white;
    border-radius: 7px;
    margin-bottom: 24px;
    border: 0;
    overflow: hidden;
    min-width: 600px;
    width: 600px;
    @include button-box-shadow();
    
    &:last-of-type {
      border-radius: 7px;
    }
    
    .sale-card-header {
      display: flex;
      align-items: center;
      padding: 6px 24px;
      
      .sale-item-count {
        padding: 0 48px;
      }
      
      .sale-card-total-price {
        flex: 1;
        text-align: right;
      }
    }
    
    .sale-card-body {
      padding: 12px 24px;
      
      td.column-money {
        text-align: right !important;
      }
    }
  }
}
