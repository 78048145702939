@import 'src/styles/breakpoints';

.page-product {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  .product-page-body {
    width: 100%;
    max-width: 884px;
    margin: 0 auto;

    .flbx-app-button {
      color: #6945E0;
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 20px;
      margin-top: -34px;
    }
  }
}

