.location-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 120px;
  min-width: 260px;
  max-width: 568px;
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(224, 224, 224, 0.7);
  border-radius: 12px;
  padding: 12px;
  padding-top: 26px;
  cursor: pointer;
  transition: all .5s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    transition: all .5s ease;
    background: #ffffffda;
  }

  .location-arrow {
    position: absolute;
    top: 42px;
    right: 12px;
  }

  .location-title {
    font-family: Ubuntu;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color: #4F4F4F;
  }

  .location-card-box-container {
    display: flex;

    .location-card-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80px;
      height: 36px;
      background: #F1F1F1;
      border-radius: 9px;
      padding: 0 15px;

      &.empty {
        justify-content: center;
      }
    
      &:first-of-type {
        margin-right: 12px;
      }

      .location-card-number {
        font-family: Ubuntu;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
}
