@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.category-editor {
  .category-editor-body {
    margin-top: 36px;
    border-radius: 24px;
    background-color: white;
  }

  &.drawer-input-container {
    padding: 36px;

    .flbx-input {
      margin: 0;
    }

    .flbx-select, .flbx-tree-select {
      margin-top: 24px;
    }
  }
}

.category-editor-header-container {
  padding: 3rem 0;
  background-color: white;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  @include media-breakpoint-up(md) {
    padding: 3rem;
    border-radius: 24px;
  }

  .category-editor-header {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 3rem 2rem;
    min-height: 13rem;
    
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .ant-breadcrumb {
      margin-right: 4rem;

      .breadcrumb-button {
        font-weight: 500;
        font-size: 3rem;
      }
        
      span:last-child {
        button {
          color: $FLEXBOX_PURPLE;
        }
      }
    }
    
    .category-editor-header-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      
      .action-button {
        min-width: 40rem;
      }

      @include media-breakpoint-up(xs) {
        justify-content: flex-end;
      }
      
      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 3rem;
      }
    }
  }
}

.category-cards {
  display: flex;
  overflow: auto;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 2rem;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &:last-of-type {
    margin-top: 6rem;
  }
}

.pos-category-category-card-container {
  width: 100%;
}

.category-card-container {
  display: flex;
  min-width: 100%;

  @include media-breakpoint-up(xs) {
    min-width: 50%;
  }
  
  @include media-breakpoint-up(md) {
    min-width: unset;
    width: 33%;
  }

  @include media-breakpoint-up(xl) {
    width: 25%;

    &:last-of-type {
      padding-right: 0;
    }
  }
  
  .category-card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 3rem;
    min-height: 11rem;
    align-items: center;
    background-color: white;
    border-radius: 3rem;
    font-size: 3rem;
    line-height: 3.5rem;
    font-family: 'Ubuntu';
    color: white;
    font-weight: 500;
    transition: all .75s ease;
    cursor: pointer;
    overflow: hidden;
    margin: 0 1rem 2rem;

    .color-code {
      position: absolute;
      left: 3rem;
      width: 2rem;
      height: 10rem;
      border-radius: 2rem;
    }
    
    &.category-card-back {
      display: flex;
      justify-content: flex-start;
      background-color: #EEEEEE;
      padding-left: 2.5rem;
      color: #2e2e2e;
      border: 1px solid #dedede;
      max-height: 11rem;

      .card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000038;
        border-radius: 2rem;
        height: 7rem;
        width: 7rem;
        min-width: 7rem;
        color: white;
        margin-right: 3rem;
      }
    }
    
    &.new-item {
      border: 3px dashed #dedede;
      background-color: transparent;
      color: #8c8c8c;
      box-shadow: none;
      padding: 0;
      justify-content: center;
    }
  }
  
  .empty-list-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    
    .empty-list-title {
      font-weight: bold;
      padding-top: 3rem;
      padding-bottom: 8rem;
    }
    
    .category-card {
      margin: 0;
    }
  }
}

.pos-category-product-cards {
  display: flex;
  flex-wrap: wrap;

  .drag-and-drop-column-title {
    padding: 3rem 3rem;
    padding-bottom: 3rem;
    font-size: 2.5rem;
    font-family: 'Ubuntu';
    color: #757575;
    font-weight: 500;
  }

  .droppable-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #d9d9d934;
    min-height: 300px;
  }
}
