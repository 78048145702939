@import 'src/styles/breakpoints';

.flbx-sales-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 272px;
  height: 284px;
  background: #FFFFFF;
  // box-shadow: 0px 0px 42px rgba(224, 224, 224, 0.7);
  border-radius: 18px;
  padding: 24px;

  @include media-breakpoint-up(xs) {
    max-width: 280px;
  }

  .flbx-sales-card-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Ubuntu;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 12px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg {
      width: 14px;
      height: 24px;
      
      path {
        fill: #F1F1F1;
      }
    }
  }

  .flbx-sales-content-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    flex: 1;

    .flbx-sales-content-title-container {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 12px;
      padding-bottom: 6px;
      font-family: Ubuntu;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #000000;
    
      &.bordered {
        border-top: 1px solid #C4C4C4;
      }

      .flbx-sales-icon-container {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: #6945E0;
        margin-right: 9px;

        &.income {
          background: #2F80ED;
        }
      }
    }

    .flbx-sales-content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #6945E0;
      padding: 12px;
      padding-top: 0;
      font-family: Ubuntu;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;

      &.income {
        color: #2F80ED;
      }
    }
  }
}