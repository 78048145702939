@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';

.page-restore-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #6945E0;
  background-size: 100% 200px;

  .restore-password-background {
    background: white;

    @include media-breakpoint-up(xs) {
      background: #6945E0;
      height: 246px;
    }
  }

  .logo {
    width: 100%;
    padding-top: 37px;
    padding-bottom: 37px;
    text-align: center;
    background: #6945E0;

    @include media-breakpoint-up(xs) {
      border: none;
      padding-top: 52px;
      padding-bottom: 52px;
    }

    .logo-normal {
      display: none;
    }
  }

  .input-container {
    margin-bottom: 12px;
    
    &:first-of-type {
      margin-top: 30px;
    }
  }

  .error-message {
    color: rgba(255, 94, 94, 0.849);
    margin-bottom: 8px;
  }
  
  .restore-password-box {
    background-color: white;
    padding: 36px 12px;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(xs) {
      height: unset;
      min-height: 366px;
      padding: 48px 42px;
      max-width: 444px;
      margin-bottom: 90px;
      @include box-shadow();
    }

    .restore-password-button-container {
      margin-top: 30px;

      .flbx-app-button {
        margin: 0;
      }
    }

    .flbx-icon-input {
      margin: 0;

      .fa-primary {
        fill: #828282;
      }

      .flbx-icon-input-field {
        padding: 6px;
      }
    }
    
    .header-container {
      display: flex;
      align-items: center;

      .fa-fingerprint {
        font-size: 36px;
        color: white;
        margin-bottom: 12px;
        margin-right: 18px;
        color: $FLEXBOX_PURPLE;

        @include media-breakpoint-up(xs) {
          font-size: 48px;
        }
      }

      .form-title {
        font-family: Comfortaa;
        font-weight: 700;
      	letter-spacing: 1px;
      	font-size: 18px;
      	line-height: 24px;
        color: $FLEXBOX_PURPLE;
      }

      .form-subtitle {
        font-family: Ubuntu;
        font-weight: 400;
      	font-size: 14px;
      	letter-spacing: 0.88px;
      	line-height: 18px;
        color: $FLEXBOX_GRAPHITE;
        margin-bottom: -6px;
        margin-top: 6px;
      }
    }
  }
}
