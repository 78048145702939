@import 'src/styles/breakpoints';

.daily-sales-carousel-container {
  width: 100%;
  margin: 0 auto;
  max-width: 450px;
  background: linear-gradient(0deg, #B9B9C5, #B9B9C5), #F1F1F1;
  box-shadow: 0px 0px 42px rgba(224, 224, 224, 0.7);
  border-radius: 18px;
  padding: 24px 0;
  margin-top: 18px;

  @include media-breakpoint-up(md) {
    max-width: 672px;
  }

  .react-multi-carousel-dot-list {
    bottom: unset;

    .sales-dot-container {
      padding: 12px 0;

      +.sales-dot-container {
        margin-left: 6px;
      }
    
      .sales-dot-item {
        width: 20px;
        height: 10px;
        border-radius: 6px;
      }

      &.inactive {
        .sales-dot-item {
          background: #F1F1F1;
        }
      }
    
      &.active {
        .sales-dot-item {
          background: #6945E0;
        }
      }
    }
  }


  .sales-carousel-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    .flbx-app-button {
      margin: 0;
      margin-top: 24px;
      font-size: 12px;
      max-width: 225px;

      a {
        color: white;
      }

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  .daily-sales-purchase {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Ubuntu;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 0 24px;
    padding-bottom: 24px;
    
    @include media-breakpoint-up(xs) {
      justify-content: flex-end;
      font-size: 18px;
    }
  }

  .daily-sales-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @include media-breakpoint-up(xs) {
      flex-direction: row;
    }

    .daily-sales-sum {
      font-family: Ubuntu;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.05em;
      padding-bottom: 12px;

      @include media-breakpoint-up(xs) {
        padding-bottom: 0;
        font-size: 24px;
      }
    }

    .daily-sales-money {
      font-family: Ubuntu;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      
      @include media-breakpoint-up(xs) {
        font-size: 44px;
        line-height: 48px;
      }
    }
  }

  .sales-card-container {
    width: 100%;
    max-width: 272px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 640px;
    }
  }
  
  .flbx-sales-card {
    @include media-breakpoint-up(md) {
      margin-left: 20px;
    }
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 3px solid #B9B9C5;
  
    svg {
      width: 10px;
      height: 10px;

      path {
        fill: #B9B9C5;
      }
    }
  
    &.left-arrow {
      left: -2px;
    
      svg {
        transform: rotate(180deg);
      }
    }
  
    &.right-arrow {
      right: -2px;
    }
  }
  
}