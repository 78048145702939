@import 'src/styles/colors';
.update-app {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: linear-gradient(200deg, $FLEXBOX_POWDER 0%, $FLEXBOX_PURPLE 100%);

  .update-app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 36px 60px;
    border-radius: 18px;
    margin-bottom: 120px;

    .update-app-icon {
      padding-bottom: 24px;
    }

    .update-app-text {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .update-app-button {
      font-size: 3rem;
      background-color: $FLEXBOX_PURPLE;
      color: white;
      padding: 12px 24px;
      border-radius: 12px;
      text-transform: uppercase;
      user-select: none;
      cursor: pointer;
    }
  }
}
