@import 'src/styles/breakpoints';

.page-product {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  background-color: #FBFBFB;

  .product-page-body {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .flbx-app-button {
      @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 24px;
      margin: 24px auto 60px;
    }
  }
}
