@import 'src/styles/breakpoints';

.page-product {
  .sales-header-container {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      height: 120px;
      background: #6945E0;
      width: 100%;
      
      .sales-header-inner {
        width: 100%;
        max-width: 976px;
        margin: 0 auto;

        .ant-breadcrumb-link, .ant-breadcrumb-separator {
          color: white!important;
          opacity: 1;
          a {
            color: white!important;
          }
        }

        .sales-header-title {
          font-family: Poppins;
          font-weight: 700;
          font-size: 48px;
          line-height: 48px;
          color: #FFFFFF;
          margin-top: 16px;
          text-transform: uppercase;
        }
      }
    }
  }

  .sales-search-container {
    position: sticky;
    top: 0;
    width: 100%;
    min-height: 72px;
    background-color: #FFFFFF;
    z-index: 1001;
    box-shadow: 0px 14px 18px 0px rgba(224, 224, 224, 0.7);
  
    .sales-search-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 18px;
      width: 100%;
      height: 100%;
      
      @include media-breakpoint-up(md) {
        flex-direction: row;
        max-width: 976px;
        margin: 0 auto;
        padding: 0;
      }

      .flbx-select {
        margin: 18px 0;

        @include media-breakpoint-up(md) {
          margin: 0;
          margin-right: 12px;
          max-width: 230px;
        }
      }

      .flbx-datepicker {
        margin-bottom: 18px;

        @include media-breakpoint-up(md) {
          margin: 0;
          max-width: 230px;
        }
      }
    }
  }
}

.sales-page-body {
  padding: 12px;

  @include media-breakpoint-up(md) {
    padding: 24px;
  }
}
