@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&subset=latin-ext');
@import 'src/styles/breakpoints';
@import 'src/styles/reset';
@import 'src/styles/common';

html {
  font-size: 6px;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  width: 100%;
  min-height: 100%;
  
  @include media-breakpoint-up(lg) {
    height: 100%;
    overflow: hidden;
  }
}
