@import 'src/styles/breakpoints';

.app-drawer.drawer-add-category {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  .location-drawer-input-container {
    padding: 36px;
  }

  .drawer-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background: #F1F1F1;

    .flbx-app-button {
      flex: unset;
      width: 100%;

      &:first-of-type {
        margin-bottom: 12px;
      }
    }

    @include media-breakpoint-up(xs) {
      flex-direction: row;

      .flbx-app-button {
        flex: 1;
        width: calc(50% - 9px);
        margin: 0;
        
        &:first-of-type {
          margin-bottom: 0;
          margin-right: 9px;
        }
  
        &:last-of-type {
          margin-left: 9px;
        }
      }
    }

  }

  .drawer-inner-container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
