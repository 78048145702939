@import 'src/styles/colors';

.assign-type-selector {
  display: flex;
  
  .assign-type {
    display: flex;
    align-items: center;
    background-color: $BODY_BACKGROUND;
    padding: 24px;
    border-radius: 9px;
    cursor: pointer;
    user-select: none;
    margin-right: 12px;

    .assign-type-icon {
      margin-right: 24px;
    }

    .assign-type-title {
      font-size: 18px;
    }
  }
}
