.daily-sales-counts, .daily-sales-income {
  background-color: white;
  margin: 30px auto;
  border-radius: 18px;
  height: 400px;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 18px;
  box-shadow: 0px 0px 42px rgba(224, 224, 224, 0.7);

  .VictoryContainer {
    min-width: 500px;
  }

  .daily-sales-counts-title {
    font-size: 18px;
    font-weight: 600;
    padding: 18px 24px;
    border-bottom: 2px solid #f1f1ef;
  }
}
