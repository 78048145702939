@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/breakpoints';


.ant-layout {
  background: unset;
}

.layout.layout-login {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  @include media-breakpoint-up(xs) {
    background-color: #DADADA;
    min-height: 100%;
    width: 100%;
  }
  
  .ant-layout-content {
    flex: auto;
    min-height: 0;
    padding-top: 0;
    width: 100%;
  }
  
  .ant-layout-footer {
    color: $FLEXBOX_TEXT_BLACK;
    background: transparent !important;
    
    @include media-breakpoint-up(xs) {
      color: white;
    }
  }
}
