@mixin box-shadow() {
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05);
  background: white;
}

@mixin panel-style() {
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05);
  background: white;
  padding: 12px;
  border-radius: 9px;
  margin: 24px 0;
}
