@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.page-discount {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #DADADA;
  overflow: auto;

  .discount-page-body {
    width: 100%;
    max-width: 884px;
    margin: 0 auto;
    
    .flbx-app-button {
      color: #6945E0;
    }
    
    @include media-breakpoint-up(md) {
      padding: 0 20px;
      margin-top: -34px;
    }
    
    .discounts-search-container {
      padding: 0 12px;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    
      .flbx-search-container {
        margin-top: 24px;
      }

      .discount-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 36px;
        margin: 0 -12px;
        
        .flbx-discount-card-container {
          flex: calc(50% - 24px);
          background-color: #FFFFFF;
          min-width: 260px;
          max-width: 568px;
          margin: 0 12px;
          margin-bottom: 30px;
        }

        .discount-placeholder {
          flex: calc(50% - 24px);
          width: 100%;
          min-width: 260px;
          max-width: 568px;
          margin: 0 12px;
        }
      }
    }
  }
}
