.empty-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  .empty-page-icon {
    color: gainsboro;
    margin: 60px 0;
  }

  .empty-page-title {
    font-weight: bold;
  }

  .empty-page-description {
    margin-top: 12px;
  }

  .empty-page-action {
    margin: 36px 0;
  }
}
